import React, {Component} from 'react'
import {sendGTMGA4Event} from "../../../utils/tagmanager";

const handleClickEvent = () => {
    sendGTMGA4Event({
        event: 'botoes',
        tipo_pagina: 'meu_estadao',
        editoria: 'meu_estadao',
        tipo_acao: 'click',
        posicao: "menu_superior",
        squad: 'conversao'
    })
}

class BotaoAssine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GTMGA4eventSended: false,
        };
    }
    render() {
        if (!this.state.GTMGA4eventSended) {
            sendGTMGA4Event({
                event: 'botoes',
                tipo_pagina: 'meu_estadao',
                editoria: 'meu_estadao',
                tipo_acao: 'show',
                posicao: "menu_superior",
                squad: 'conversao'
            })

            this.setState({GTMGA4eventSended: true})
        }
        return (
            <div className="assine">
                <a
                    href={`https://assine.estadao.com.br/landingpage?utm_source=meu_estadao_menusuperior&utm_medium=meu_estadao_menusuperior&utm_campaign=meu_estadao_menusuperior&utm_content=meu_estadao_menusuperior&referrer_url=${window.location.href}`}
                   data-dtkey={"botao_assine_" + this.props.dtkey}
                   className="btn green"
                   target="_blank" onClick={handleClickEvent}>Assinar Agora</a>
            </div>
        )
    }
}

export default BotaoAssine