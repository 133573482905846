import React, { Component } from "react";
import ContentLoader from "./../../content-loader/content-loader";
import { connect } from "react-redux";
//import {Link} from 'react-router-dom'
import { HashLink as Link } from "react-router-hash-link";
import FilterLinks from "../../../utils/filterLinks";
import subscriptionActions from "../../../actions/subscription";
import { sendGTMGA4Event } from "../../../utils/tagmanager";

const ServicosLoader = (props) => (
  <ContentLoader
    height={"280"}
    width={"1000"}
    style={{ width: "100%", height: "280" }}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="10" rx="5" ry="5" width="300" height="35" />
    <rect x="0" y="65" rx="5" ry="5" width="100%" height="225" />
  </ContentLoader>
);

class Servicos extends Component {
  servicos(assinatura) {
    let links = FilterLinks();
    let servicos = {};
    if (assinatura.config.type === "IMP") {
      servicos = {
        alterar_forma_pagamento: {
          icon: "icon-credit-card",
          text: "Alterar forma de pagamento",
          url: links.get("forma_de_pagamento"),
        },
        alteracao_de_endereco: {
          icon: "icon-doc-text",
          text: "Alteração de Endereço de entrega",
          url: links.get("alteracao_de_endereco"),
        },
        transferencia_temporaria: {
          icon: "icon-arrows-cw",
          text: "Transferência temporária de endereço",
          url: links.get("transferencia_temporaria"),
        },
        historico_pagamento: {
          icon: "icon-list-alt",
          text: "Histórico de pagamentos",
          url: links.get("historico_de_pagamento"),
        },
        interrupcao_temporaria: {
          icon: "icon-calendar-check-o",
          text: "Interrupção temporária de entrega",
          url: links.get("interrupcao_temporaria"),
        },
        reclamacao_entrega: {
          icon: "icon-megaphone",
          text: "Reclamação de entrega",
          url: links.get("reclamacao_de_entrega"),
        },
      };
    } else {
      servicos = {
        alterar_forma_pagamento: {
          icon: "icon-credit-card",
          text: "Alterar forma de pagamento",
          url: links.get("forma_de_pagamento"),
        },
        alterar_dados_pagamento_assinatura: {
          icon: "icon-credit-card",
          text: "Alterar dados de meio de pagamento",
          url: links.get("forma_de_pagamento"),
        },
        nota_fiscal: {
          icon: "icon-barcode",
          text: "Fatura",
          url: links.get("nota_fiscal"),
        },
        historico_pagamento: {
          icon: "icon-list-alt",
          text: "Histórico de pagamento",
          url: links.get("historico_de_pagamento"),
        },
        informacao_jornal_digital: {
          icon: "icon-newspaper",
          text: "Informações jornal digital",
          url: "/perguntas-frequentes#jornal-digital",
        },
        alterar_email_senha_acesso: {
          icon: "icon-mail",
          text: "Alterar email/senha de acesso",
          url: links.get("meu_perfil"),
        },
      };
    }

    return servicos;
  }

  componentDidUpdate() {
    if (!this.props.assinatura_dados && this.props.assinatura_selecionada) {
      this.props.dispatch(
        subscriptionActions.getDados(this.props.assinatura_selecionada)
      );
    }
  }

  sendGTMGA4Event = ({ service }) => {
    sendGTMGA4Event({
      event: "meu_estadao",
      secao: "servicos_mais_acessados",
      nome_campo: service,
    });
  };

  render() {
    const selecionada = this.props.selecionada;
    const assinaturas = this.props.assinaturas;
    const assinatura = assinaturas.hasOwnProperty(selecionada)
      ? assinaturas[selecionada]
      : { config: { type: "DIG" } };

    let servicos = this.servicos(assinatura);

    if (!this.props.assinatura_dados && this.props.assinatura_selecionada) {
      this.props.dispatch(
        subscriptionActions.getDados(this.props.assinatura_selecionada)
      );
    }

    return assinatura || (this.props.user.id && !this.props.user.assinante) ? (
      <div id="servicos-container" className="block-cards-body">
        <div className="header">
          <h3 className="title">Serviços mais acessados</h3>
        </div>

        <div className="block">
          {!this.props.user.assinante &&
            !this.props.user.block && (
              <div className="lock">
                <div className="center">
                  <i className="icon-round-lock" />
                  <p>Serviço disponivel somente para assinantes</p>
                  <p>
                    Seja assinante, clicando{" "}
                    <a
                      data-dtkey="seja_assinante"
                      href={`https://ofertas.estadao.com.br/?sub-canal=botao&posicao=meu-estadao-seja-assinante&referrer_url=${
                        window.location.href
                      }`}
                      target="_blank"
                    >
                      aqui
                    </a>
                  </p>
                </div>
              </div>
            )}
          <ul className="links">
            {Object.keys(servicos).map((i, key) => {
              let item = servicos[i];
              let dtkey = "servicos_" + i;
              let gaLabel = i;
              let disabled;
              if (i === "contrato_assinatura") {
                disabled = !item.default.url;
                if (
                  this.props.assinatura_dados &&
                  this.props.assinatura_dados.tipo_publicacao === "SWG"
                ) {
                  return (
                    <li
                      key={key}
                      className={item.default.url ? "" : "disabled"}
                    >
                      <Link
                        data-dtkey={disabled ? "" : dtkey}
                        to={item.default.url ? item.default.url : ""}
                        onClick={(e) => {
                          if (!item.default.url) {
                            e.preventDefault();
                            return false;
                          }
                          this.sendGTMGA4Event({ service: gaLabel });
                        }}
                      >
                        <i className={item.default.icon} />
                        <span>{item.default.text}</span>
                      </Link>
                    </li>
                  );
                } else {
                  disabled = !item.alterar_forma_pagamento.url;
                  return (
                    <li
                      key={key}
                      className={
                        item.alterar_forma_pagamento.url ? "" : "disabled"
                      }
                    >
                      <Link
                        data-dtkey={disabled ? "" : dtkey}
                        to={
                          item.alterar_forma_pagamento.url
                            ? item.alterar_forma_pagamento.url
                            : ""
                        }
                        onClick={(e) => {
                          if (!item.alterar_forma_pagamento.url) {
                            e.preventDefault();
                            return false;
                          }
                          this.sendGTMGA4Event({ service: gaLabel });
                        }}
                      >
                        <i className={item.alterar_forma_pagamento.icon} />
                        <span>{item.alterar_forma_pagamento.text}</span>
                      </Link>
                    </li>
                  );
                }
              } else {
                disabled = !item.url;
                return (
                  <li key={key} className={item.url ? "" : "disabled"}>
                    <Link
                      data-dtkey={disabled ? "" : dtkey}
                      to={item.url ? item.url : ""}
                      onClick={(e) => {
                        if (!item.url) {
                          e.preventDefault();
                          return false;
                        }
                        this.sendGTMGA4Event({ service: gaLabel });
                      }}
                    >
                      <i className={item.icon} />
                      <span>{item.text}</span>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    ) : (
      <div className="block-cards-body">
        <ServicosLoader />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assinaturas: state.assinaturas.list,
    inadimplente: state.inadimplente,
    selecionada: "1157282-1",
    assinatura:
      Object.keys(state.assinaturas.list).length > 0 &&
      state.assinaturas.list.hasOwnProperty(
        state.assinaturas.assinatura_selecionada
      )
        ? state.assinaturas.list[state.assinaturas.assinatura_selecionada]
        : false,
    assinatura_selecionada: state.assinaturas.assinatura_selecionada,
    assinatura_dados: state.assinatura.hasOwnProperty(
      state.assinaturas.assinatura_selecionada
    )
      ? state.assinatura[state.assinaturas.assinatura_selecionada].assinatura
      : null,
    pagador: state.assinatura.hasOwnProperty(
      state.assinaturas.assinatura_selecionada
    )
      ? state.assinatura[state.assinaturas.assinatura_selecionada].pagador
      : null,
  };
};

export default connect(mapStateToProps)(Servicos);
