import React, { Component } from 'react'
import { sendGTMGA4Event } from '../../utils/tagmanager'

const handleClick = () => {
    sendGTMGA4Event({
        event: 'botoes',
        tipo_pagina: 'meu_estadao',
        editoria: 'meu_estadao',
        tipo_acao: 'click',
        posicao: "selo_superior",
        squad: 'conversao'
    })
}

class SeloSuperior extends Component {
    render() {
        return (
            <div className="assinaturas text-center float-right">
                <a href={`https://assine.estadao.com.br/landingpage?utm_source=meu_estadao_selosuperior&utm_medium=meu_estadao_selosuperior&utm_campaign=meu_estadao_selosuperior&utm_content=meu_estadao_selosuperior&referrer_url=${window.location.href}`}
                   onClick={handleClick} target="_blank" className="btn green">ASSINE O ESTADÃO</a>
            </div>
        )
    }
}

export default SeloSuperior