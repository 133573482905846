import React from 'react';
import Input from './Input';
import Form from './../Form';
import InputMask from 'react-input-mask';
import axios from "axios";
import Loading from "../Loading";
import env from "../../env";

class InputCEP extends Input {

    constructor(props) {
        super(props)

        this.state.search = this.props.search || false;
        this.state.dataTeste = this.props.dataTeste || '';
        this.state.searching = false;
        this.handleChangeCep = this.handleChangeCep.bind(this);
        this.beforeMaskedValueChange = this.beforeMaskedValueChange.bind(this);
        this.state.endereco_rua = ""
        this.state.endereco_bairro = ""
        this.state.endereco_tipo_logradouro = ""
    }

    handleChangeCep = e => {
        if (this.state.search) {
            let cep = e.target.value.replace(/_/g, "").replace(/-/g, "");
            if (cep.length === 8) {
                this._searchAddress(cep);
            }
        }
        this.handleChange(e);
    }

    _searchAddress(cep) {
        if (cep && this.state.search && this.state.searching !== cep) {
            let callback = this.props.callback || function () {
            };
            this.setState({
                searching: cep
            });
            Loading.add();
            let error = {};
            error[this.state.name] = "CEP não encontrado!";
            
            axios.get(`${env.API_URL}/utils/address/search-sap/${cep}`, { withCredentials: true }).then(response => {
                let search = this.state.search;
                let data = {
                    bairro: response.data.data.localizacao.NM_BAIRRO,
                    cep: cep,
                    estado: response.data.data.localizacao.SG_UF,
                    localidade: response.data.data.localizacao.NM_LOCALIDA,
                    logradouro: response.data.data.localizacao.NM_LOGRAD,
                    endereco_tipo_logradouro: response.data.data.localizacao.TP_LOGRAD,
                    uf: response.data.data.localizacao.SG_UF
                }
                Loading.remove();


                if (!data.erro) {
                    for (let i in search) {
                        let element = document.querySelector('[name="' + i + '"]');
                        if (element && data.hasOwnProperty(search[i])) {
                            element.setValue(data[search[i]]);
                        }
                    }
                } else {
                    Form.showErrors(error);
                }
                callback(data);
            }).catch(error => {
                error.messageError = "Este CEP não se encontra na nossa base, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).";
                callback({erro: error.messageError});
                Form.showErrors(error.messageError);
                Loading.remove();
            });
        }
    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {
        var {value} = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = {start: cursorPosition, end: cursorPosition};
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    render() {
        return (
            <div ref={el => this.input_cep = el || this.input_cep}
                 className={this.state.className.join(" ") + (this.state.isset ? " isset" : "")}>
                {!this.state.load ? (
                    <div>
                        <InputMask readOnly={this.state.readonly} ref={el => this.input = el || this.input}
                                   mask="99999-999"
                                   data-teste={this.state.dataTeste} maskChar={null}
                                   beforeMaskedValueChange={this.beforeMaskedValueChange} type={this.state.type}
                                   id={this.state.id} name={this.state.name}
                                   className={(!this.state.label ? "no-label" : "")}
                                   onChange={(e) => this.handleChangeCep(e)} defaultValue={this.state.value}/>
                        {this.state.label ? (
                            <label htmlFor={this.state.id}>{this.state.label}</label>
                        ) : ""}
                    </div>
                ) : this.loader()}
            </div>
        )
    }
}

export default InputCEP;
