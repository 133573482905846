import React, { Component } from 'react'
import Grid from '../../../modules/Grid'
import DocumentHead from '../../../../utils/DocumentHead'
import Assinatura from '../../../assinaturas'
import AlertMessage from '../../../alert-message/alertMessage'
import Titulo from "../../../modules/Titulo";
import Formulario from "./formulario";
import {connect} from "react-redux";
import FilterLinks from "../../../../utils/filterLinks";
import subscriptionActions from "../../../../actions/subscription";
import alertMessageActions from '../../../../actions/alertMessage';
import ContentLoader from "../../../content-loader/content-loader";
import SWG from "../swg";

class AlterarFormaDePagamento extends Component {

    status = {
        EM_ANDAMENTO: 1,
        RENEGOCIADO: 2,
        APROVADO: 3,
        NOVO: 4,
        REJEITADO: 6,
    };

    state = {
        disabled: false
    };

    componentDidMount() {
        DocumentHead({
            title: 'Meus Pagamentos - Alterar Forma de Pagamento – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
        if(this.props.user !== "undefined"&&this.props.user.colaborador){
            window.location.href = window.location.origin;
        }
        this.componentDidUpdate();
    }

    componentWillUnmount() {
        if(this.state.disabled) {
            this.props.dispatch(alertMessageActions.removeAlert());
        }
    }

    componentDidUpdate() {
        if(!this.props.assinatura && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }

        if(this.props.assinatura && this.props.assinatura.tipo_publicacao !== "SWG" && !this.props.parcela_em_aberto && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionActions.consultaParcelaEmAberto(this.props.assinatura_selecionada))
        }

        let links = FilterLinks();
        if(this.props.assinatura_selecionada && !links.get('forma_de_pagamento')){
            this.props.dispatch({type: "REDIRECT", redirect: "/"});
        }

        if(this.props.parcela_em_aberto){
            let disabled = this.props.parcela_em_aberto.status === this.status.REJEITADO ||
                ( [this.status.EM_ANDAMENTO, this.status.NOVO].indexOf(parseInt(this.props.parcela_em_aberto.status)) !== -1 &&
                    this.props.parcela_em_aberto.vencido
                );

            if(this.state.disabled !== disabled){
                this.setState({
                    disabled: disabled
                });

                if(disabled){
                    this.props.dispatch(alertMessageActions.setAlert('blue', "Neste período do mês o serviço encontra-se em processamento e não será possível alterar a forma de pagamento, tente daqui há alguns dias.", false));
                }else{
                    this.props.dispatch(alertMessageActions.removeAlert());
                }
            }
        }
    }

    render() {
        let load = !this.props.parcela_em_aberto;
        let forma_pagamento = this.props.assinatura && this.props.assinatura.tipo_publicacao === "SWG" ? "Assinatura via Google" :
                                    this.props.parcela_em_aberto ? this.props.parcela_em_aberto.forma_pagamento_descricao : null;
        return (
            <div className="container forma-de-pagamento justify-content-center">
                <div className="header-content row">
                    <Grid cols='12 12 7 6 6' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Meus pagamentos" titulo="forma de pagamento"/>
                    </Grid>
                    <Grid cols='12 12 5 6 6' class='text-right'>
                        <Assinatura />
                    </Grid>

                    <AlertMessage />
                </div>

                <div className="row data-form">
                    <Grid cols="12 12 12 3 3">
                        <div className="block-single text">
                            <h3>Status</h3>
                            <p>
                                Forma de Pagamento Atual:<br/>
                                {!forma_pagamento ? (
                                    <ContentLoader
                                        height={30}
                                        width={250}
                                        style={{width: '100%', height: '30', marginTop: "2px"}}
                                        speed={1}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb">
                                        <rect x="0" y="0" rx="5" ry="5" width="250" height="30" />
                                    </ContentLoader>
                                ) : (
                                    <b>{forma_pagamento}</b>
                                )}
                            </p>
                        </div>
                    </Grid>

                    <Grid cols="12 12 12 9 9" class="inner-content">
                        {this.props.assinatura && this.props.assinatura.tipo_publicacao === "SWG" ? (
                            <SWG {...this.props} />
                        ) : !load ? (
                            <Formulario disabled={this.state.disabled} {...this.props}/>
                        ) : (
                            <ContentLoader
                                height={400}
                                width={900}
                                style={{width: '100%', height: '400px'}}
                                speed={1}
                                primaryColor="#f3f3f3"
                                secondaryColor="#ecebeb">
                                <rect x="0" y="0" rx="5" ry="5" width="900" height="400" />
                            </ContentLoader>
                        )}
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let parcelas_em_aberto = state.parcela_em_aberto.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.parcela_em_aberto[state.assinaturas.assinatura_selecionada] : null;
    let assinatura = state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada].assinatura : null;
    return {
        user: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: assinatura,
        parcela_em_aberto: parcelas_em_aberto ? parcelas_em_aberto[Object.keys(parcelas_em_aberto)[Object.keys(parcelas_em_aberto).length - 1]] : null
    }
};

export default connect(mapStateToProps)(AlterarFormaDePagamento)
