import React, { Component } from 'react'
import {connect} from 'react-redux'
import CartaoDeCredito from "../cartao-de-credito";
import DebitoEmConta from "../debito-em-conta";
import Paypal from "../pagar-agora/paypal/paypal";
//import BoletoPorEmail from "../boleto-por-email";
import Loading from "../../../../utils/Loading";
import alertMessageActions from "../../../../actions/alertMessage";
import {Form} from "../../../../utils/Form";
import subscriptionActions from "../../../../actions/subscription";
import { sendGTMGA4Event } from '../../../../utils/tagmanager'

class Formulario extends Component {

    constructor(props){
        super(props);

        this.alterarFormaDePagamento = this.alterarFormaDePagamento.bind(this);
    }

    state = {
        tab_select: "credit",
    }

    handleChangeTab(tab){
        this.setState({
            tab_select: tab
        })
    }

    sendGTMGA4Event = ({ method }) => {
        const payment_method = {
            'credit': 'cartao_credito',
            'debit': 'cartao_debito'
        }

        const adimplente = this.props.inadimplente 
            && this.props.inadimplente.cod_retorno === '1' 
            && this.props.inadimplente.txt_msg === ''

        sendGTMGA4Event({
            event: 'meu_estadao',
            secao: 'meus_pagamentos_' + (adimplente ? 'adimplente' : 'inadimplente'),
            nome_campo: 'atualizar_pagamento_' + payment_method[method] || method || ''
        })
    }

    alterarFormaDePagamento = () => {
        let form;

        switch (this.state.tab_select) {
            case "credit":
                form = document.getElementById('form-alterar-forma-de-pagamento-cartao-de-credito');
            break;
            case "debit":
                form = document.getElementById('form-alterar-forma-de-pagamento-debito-em-conta');
            break;
            case "boleto-por-email":
                form = document.getElementById('form-alterar-forma-de-pagamento-boleto-por-email');
            break;
            default:
                form = false;
            break;
        }

        if(!this.props.disabled && form){
            let bodyFormData = new FormData(form);
            bodyFormData.append('forma_pagamento_de', this.props.parcela_em_aberto.forma_pagamento);
            bodyFormData.append('ciclo', this.props.parcela_em_aberto.ciclo);

            Loading.add();
            this.props.dispatch(alertMessageActions.removeAlert());
            Form.send("/subscription/"+this.props.assinatura_selecionada+"/change-payment-method", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;
                if(status === 200){
                    this.sendGTMGA4Event({ method: this.state.tab_select })
                    this.props.dispatch(subscriptionActions.consultaParcelaEmAberto(this.props.assinatura_selecionada, {enable_reload: true}));

                    this.props.dispatch(alertMessageActions.setAlert('green', data.message || 'Salvo com sucesso!', false));
                    this.props.dispatch({type: "REDIRECT", redirect: "/"});
                }else if(data.error){
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message,20000);
                }
            }).catch((e) => {
                Loading.remove();
            });
        }
    }

    render(){
        let tab_list = {
            "credit": "Cartão de Crédito",
            "debit": "Débito em Conta",
            "paypal": "Paypal",
        };
        
        if(
            (this.props.assinatura.codigo_meio_de_pagamento === '6' || this.props.assinatura.codigo_meio_de_pagamento === 'J') &&
            (this.props.parcela_em_aberto.zterm != 'CC01' && this.props.parcela_em_aberto.zterm != 'PY01')
        ) {
            tab_list = {
                "credit": "Cartão de Crédito",
            };
        }

       /* //Caso Forma de Pagamento for Boleto
        if(this.props.parcela_em_aberto && this.props.parcela_em_aberto.forma_pagamento === "D"){
            tab_list["boleto-por-email"] = "Boleto por e-mail";
        }*/


        return (
            <div>
                <ul className="tab-list" data-length={Object.keys(tab_list).length}>
                    {Object.keys(tab_list).map((i, key) => {
                        return (
                            <li key={key} className={!this.props.disabled && this.state.tab_select === i ? "active" : ""} onClick={ () => this.handleChangeTab(i) }>
                                <span>{tab_list[i]}</span>
                            </li>
                        );
                    })}
                </ul>
                <div className="block-single text">
                    <div className="lock-size-form">
                        {(() => {
                            switch(this.state.tab_select) {
                                case 'credit':
                                    return <CartaoDeCredito load={this.props.load} disabled={this.props.disabled} manter={true}/>
                                case 'debit':
                                    return <DebitoEmConta load={this.props.load} disabled={this.props.disabled} />
                                case 'paypal':
                                    return <Paypal load={this.props.load} disabled={this.props.disabled} />
                                /*case 'boleto-por-email':
                                    return <BoletoPorEmail load={this.props.load} disabled={this.props.disabled} />*/
                                default:
                                    return null;
                            }
                        })()}
                        {(() => {
                            if (this.state.tab_select != 'paypal') {
                                return <button className={"btn blue salvar-dados"+(this.props.disabled ? " disabled" : "")} data-dtkey="altualizar_forma_de_pagamento" onClick={this.alterarFormaDePagamento} ><i className="icon-arrows-cw" /> Atualizar forma de pagamento</button>
                            }
                        })()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        inadimplente: state.inadimplente,
    }
}

export default connect(mapStateToProps)(Formulario) ;