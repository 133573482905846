import React, { Component } from 'react'
import CartaoDeCredito from "../cartao-de-credito";
import alertMessageActions from "../../../../actions/alertMessage";
import Loading from "../../../../utils/Loading";
import {Form} from "../../../../utils/Form";
import subscriptionActions from "../../../../actions/subscription";

class Formulario extends Component {

    constructor(props){
        super(props);

        this.pagarAgora = this.pagarAgora.bind(this);
    }

    state = {
        showForm: true
    }

    handleChangeTab(tab){
        this.setState({
            tab_select: tab
        })
    }

    pagarAgora(){
        let form = document.getElementById('form-alterar-forma-de-pagamento-cartao-de-credito');
        if(!this.props.disabled && form){
            let bodyFormData = new FormData(form);
            bodyFormData.append('ciclo', this.props.parcela_em_aberto.ciclo);

            Loading.add();
            this.props.dispatch(alertMessageActions.removeAlert());
            Form.send("/subscription/"+this.props.assinatura_selecionada+"/pay-now", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;
                if(status === 200){

                    this.props.dispatch(subscriptionActions.consultaParcelaEmAberto(this.props.assinatura_selecionada, {enable_reload: true}));
                    this.props.dispatch(subscriptionActions.getHistoricoPagamentos(this.props.assinatura_selecionada, {enable_reload: true}));

                    this.props.dispatch(alertMessageActions.setAlert('green', data.message || 'Salvo com sucesso!', 10000));

                    setTimeout(() => {
                        this.props.dispatch({type: "REDIRECT", redirect: "/historico-de-pagamentos"});
                    },5000);

                }else if(data.error){
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message,20000);
                }
            }).catch((e) => {
                Loading.remove();
            });
        }
    }

    render(){
        return (
            <div>
                <div className="block-single text">
                    <div className="lock-size-form">
                        { this.state.showForm && <CartaoDeCredito disabled={this.props.disabled} manter={false}/> }
                        <button className={"btn blue salvar-dados"+(this.props.disabled ? " disabled" : "")} data-dtkey="pagar_agora" onClick={this.pagarAgora}><i className="icon-credit-card" /> Pagar Agora</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Formulario;
